// Fonts
@import url('https://fonts.googleapis.com/css?family=Prompt');

// Variables
@import 'variables';



// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~admin-lte/src/scss/adminlte';
@import '~datatables.net-bs5/css/dataTables.bootstrap5.css';
@import '~datatables.net-buttons-bs5/css/buttons.bootstrap5.css';
@import '~bootstrap-icons/font/bootstrap-icons';
@import '~toastr';

